$susy: (
        'svg-grid-colors': hsl(180, 50%, 80%),
        'columns': susy-repeat(12),
        'container-spread': 'wide',
);

.container {
  //margin: 1rem 0;
  //padding: 0.5rem 0;
  //outline: 1px solid;
  //background: susy-svg-grid() no-repeat scroll;;


  &::after {
    clear: both;
    content: ' ';
    display: block;
  }
  [data-span] {
    float: left;
    text-align: right;
    margin: 0 gutter() / 2;

    //border: 1px solid #000;
    box-sizing: border-box;

  }
  [data-span='4'] {
    width: span(12);
  }

  @media only screen and (min-width: $mediumScreens) {
    @for $i from 1 through 12 {

      [data-span="#{$i}"] {
        text-align: center;
        width: span($i);
      }
    }

  }
  @media only screen and (min-width: $largeScreens) {


  }

}






