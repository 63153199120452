
$mainColor: #000;
//$mainColor: #16ffb5;
$textColorOverPrimaryColor:#000;
$secondaryColor:#565656;
$textColorOverSecondaryColor:#fff;
$rojo: #ED1845;

$bigScreens:1600px;
$largeScreens:1000px;
$mediumScreens:480px;




$lessSkew:-0deg;
$lessSkewplus:+0deg;
$skew:-0deg;
$skewplus:+0deg;


//$skew:0deg;
//$skewplus:0deg;
