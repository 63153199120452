
.page-header {
  color: #000;
  padding-left: 10px;
  font-size: 20px;

}

.container.tag {
  //margin-top: 180px;

  &.skew {
    transform: translateZ(1px) skewY($skew);
    overflow: hidden;
    //margin-bottom: 150px;
    //margin-top: 180px;
  }
  h1 {
    color: $secondaryColor;
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: 18px;
  }

  .projects-container {

    > .project {
      margin: 0 0;

      box-sizing: border-box;
      float: left;
      // width: span(12);
      width: 100%;
      //overflow: hidden;
      position: relative;
      .aspect-image {
        opacity: 0;
        min-height: 50px;
        transform: translateZ(1px) skewY($skewplus) scale(1);
      }
      .overlay {
        position: absolute;
        height: 100%;
        // transform: scale(1.2);
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
        transition: all 100ms;
        opacity: 0;
      }
      .project-container {
        //height: 288px;
        overflow: hidden;
        position: relative;
        .background-container {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          top: 0;
          left: 0;
          img {
            opacity: 1;
            width: 100%;
            // margin-top: -10%;
            transition: all 300ms;

            // transform: skewY($skewplus) scale(1.1);

          }

        }
      }

      //.project-text:nth-child(odd){
      //  background: linear-gradient(to left, #d03b94 0%, #dd2c47 100%);
      //}

      .project-text {
        position: absolute;
        width: 100%;
        opacity: 0;
        bottom: 0;
        lef: 0;
        z-index: 1;
        background:#ed1845;
        //background: linear-gradient(to right, #d03b94 0%, #dd2c47 100%);
        color: $textColorOverSecondaryColor;
        text-align: center;
        margin-top: 0%;
        transform: scale(1);

        transition: all 300ms;
        min-height: 70px;
        padding: 5px 10px 5px 10px;
        box-sizing: border-box;

        h3 {
          font-size: 15px;
          font-weight: 100;
          text-transform: uppercase;
        }
        p {
          font-size: 12px;
          font-weight: 100;

          padding: 0px 45px 10px;

        }
      }
      &:hover {
        .overlay {
          //display: block;
          opacity: 1;
        }
        .background-container {
          img {
            transform: skewY($skewplus) scale(1.05);
          }
        }
        .project-text {
          background: #ed1845;
          color: #fff;
          transform: scale(1.4);
          opacity: 1;
          h3 {

            //font-size: 20px;
          }
        }
      }

    }
  }

  @media only screen and (min-width: $mediumScreens) {
    &.skew {
      //margin-top: 200px;
    }
    h1 {
      font-size: 45px;
    }
    .projects-container {
      > .project {
        // width: span(6);
        width: 50%;
        .project-text {
          p {
            padding: 0px 100px 10px;
          }
        }
      }
    }

  }
  @media only screen and (min-width: $largeScreens) {
    &.skew {
      margin-top: 0px;
    }
    .projects-container {
      > .project {
        // width: span(4);
        // width: 33.33%;
      }
    }
  }
  @media only screen and (min-width: $bigScreens) {
    .projects-container {
      > .project {
        // width: span(3);
        width: 33.33%;
      }

    }
  }
}

.black-container {
  article {
    position: relative;

    .aspect-image {
      display: none;

      @media only screen and (min-width: $largeScreens) {
        display: block;
      }

      img {
        // height: 242px;
      }
    }

    header {
      position: relative;
      width: 100%;
      top: 0;
      height: 100%;
      left: 0;
      color: $rojo;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(222,222,222,1) 100%);
      background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(222,222,222,1) 100%);
      background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(222,222,222,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dedede',GradientType=1 );

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 40px;

      @media only screen and (min-width: $largeScreens) {
        position: absolute;
      }

      &.with-background {
        justify-content: flex-start;
        color: white;

        .cont-header {
          max-width: 600px;
        }
      }

      .cont-header {
        padding: 0;
        max-width: 960px;
        width: 100%;

        @media only screen and (min-width: $largeScreens) {
          // padding: 0 0 40px;
          max-width: 820px;
          // max-width: 1070px;
        }
        @media only screen and (min-width: 1366px) {
          max-width: 815px;
        }
        @media only screen and (min-width: $bigScreens) {
          max-width: 815px;
        }

        h1 {
          width: 100%;
          font-size: 30px;
          font-weight: 700;
          padding: 0 0 40px;

          @media only screen and (min-width: $mediumScreens) {
            width: 100%;
            // padding: 40px 0;
          }
          // @media only screen and (min-width: $largeScreens) {
          //   width: calc(100% - 255px);
          // }
          // @media only screen and (min-width: $bigScreens) {
          //   width: calc(100% - 555px);
          // }
        }
        p {
          max-width: 1150px;
          // padding: 20px 40px 0;
          width: 100%;
          margin: 0 auto;
          font-size: 20px;

          @media only screen and (min-width: $mediumScreens) {
            width: 100%;
          }
          // @media only screen and (min-width: $largeScreens) {
          //   width: calc(100% - 255px);
          // }
          // @media only screen and (min-width: $bigScreens) {
          //   width: calc(100% - 555px);
          // }
        }

        a {
          background: #ED1845;
          color: white;
          font-size: 15px;
          padding: 5px 10px;
          margin-top: 20px;
          display: table;

          &:hover {
            background: black;
          }
        }
      }
    }

  }
}
