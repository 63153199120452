@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800,900");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
}

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transform: translateY(-40px);
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}
.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr--hide-controls .plyr__captions {
  transform: translateY(-15px);
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.plyr__control--overlaid svg {
  height: 20px;
  left: 2px;
  position: relative;
  width: 20px;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #1aafff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls > .plyr__control {
  margin-left: 5px;
}

.plyr__controls .plyr__menu:first-child, .plyr__controls .plyr__menu:first-child + [data-plyr=pause], .plyr__controls .plyr__progress:first-child, .plyr__controls .plyr__progress:first-child + [data-plyr=pause], .plyr__controls .plyr__time:first-child, .plyr__controls .plyr__time:first-child + [data-plyr=pause], .plyr__controls > .plyr__control:first-child, .plyr__controls > .plyr__control:first-child + [data-plyr=pause] {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__volume {
  margin-left: 5px;
}

@media (min-width: 480px) {
  .plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls > .plyr__control {
    margin-left: 10px;
  }
  .plyr__controls .plyr__menu + .plyr__control, .plyr__controls > .plyr__control + .plyr__control, .plyr__controls > .plyr__control + .plyr__menu {
    margin-left: 5px;
  }
}
.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 35px 10px 10px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 2;
}

.plyr--video .plyr__controls .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px;
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none;
}

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.plyr__video-embed iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: "";
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0;
}

.plyr__menu__container ul {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 7px;
}

.plyr__menu__container ul li {
  margin-top: 2px;
}

.plyr__menu__container ul li:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px;
}

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: "";
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container label.plyr__control {
  padding-left: 7px;
}

.plyr__menu__container label.plyr__control input[type=radio] + span {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container label.plyr__control input[type=radio] + span::after {
  background: #fff;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container label.plyr__control input[type=radio]:checked + span {
  background: #1aafff;
}

.plyr__menu__container label.plyr__control input[type=radio]:checked + span::after {
  opacity: 1;
  transform: scale(1);
}

.plyr__menu__container label.plyr__control input[type=radio]:focus + span {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0;
}

.plyr__menu__container label.plyr__control.plyr__tab-focus input[type=radio] + span, .plyr__menu__container label.plyr__control:hover input[type=radio] + span {
  background: rgba(0, 0, 0, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -7px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 28px;
  color: #1aafff;
  display: block;
  height: 20px;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all 0.2s ease;
  width: 14px;
  -webkit-appearance: none;
  margin-top: -4px;
}

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all 0.3s ease;
  -moz-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all 0.2s ease;
  width: 14px;
}

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 3px;
  height: 6px;
}

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  height: 6px;
  transition: all 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  box-sizing: border-box;
  height: 14px;
  position: relative;
  transition: all 0.2s ease;
  width: 14px;
  margin-top: 0;
}

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type=range]:focus {
  outline: 0;
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 3px rgba(26, 175, 255, 0.35);
  outline: 0;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
  pointer-events: none;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time::before {
  content: "⁄";
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}
.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}

.plyr--video {
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__progress {
  display: flex;
  flex: 1;
  left: 7px;
  margin-right: 14px;
  position: relative;
}

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 6px;
  left: 0;
  margin-top: -3px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 6px;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 6px;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66);
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr__volume {
  flex: 1;
  position: relative;
}

.plyr__volume input[type=range] {
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 50px;
  }
}
@media (min-width: 768px) {
  .plyr__volume {
    max-width: 80px;
  }
}
.plyr--is-ios .plyr__volume {
  display: none !important;
}

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important;
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}
.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}
.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}
.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}
.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-fallback .plyr__video-embed {
  overflow: visible;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}
.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 6px;
  left: 0;
  margin: -3px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 200;
  background: #fff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2d2f48+0,000000+100 */
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-master {
  background-color: transparent;
  margin-top: -1px;
  overflow: visible;
}

.logo {
  width: 100px;
  height: 53px;
}
.logo img {
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus {
  outline: none;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;
}
.videoWrapper .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(26, 175, 255);
  width: 80px;
  height: 80px;
  top: 50%;
}
.videoWrapper .plyr--full-ui.plyr--video .plyr__control--overlaid svg {
  height: 30px;
  left: auto;
  position: relative;
  width: 30px;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 50px 0;
}

.hide-for-small {
  display: none;
}
@media only screen and (min-width: 480px) {
  .hide-for-small {
    display: block;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;
}
.header .logo {
  width: 100px;
  margin: -3px 0 0 -11px;
}
.header nav {
  flex-basis: calc(100% - 0px);
  margin: 20px 0 20px 0;
}
@media only screen and (min-width: 1000px) {
  .header nav {
    flex-basis: calc(100% - 366px);
    margin: 0px;
    height: 95px;
  }
}
@media only screen and (min-width: 480px) {
  .header {
    max-width: 960px;
  }
}
@media only screen and (min-width: 1000px) {
  .header {
    max-width: 1150px;
    padding: 40px 40px 0;
  }
}

nav {
  height: auto;
}
nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 480px) {
  nav ul {
    justify-content: normal;
  }
}
nav ul li {
  text-align: center;
  box-sizing: border-box;
  float: right;
  padding: 0px;
  list-style-type: none;
}
@media only screen and (min-width: 480px) {
  nav ul li {
    padding: 2px 10px 5px 10px;
  }
}
nav ul li:first-child {
  padding: 0px;
}
@media only screen and (min-width: 480px) {
  nav ul li:first-child {
    padding: 2px 10px 5px 0px;
  }
}
@media only screen and (min-width: 1000px) {
  nav ul li:first-child {
    padding: 2px 10px 5px 10px;
  }
}
nav ul li.ochotreinta a {
  color: #EE3649;
}
nav ul li a, nav ul li span {
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #000;
  cursor: pointer;
}
nav ul li a:hover, nav ul li span:hover {
  color: #565656;
}
@media only screen and (min-width: 1000px) {
  nav {
    display: block;
  }
}

.nav-lang {
  width: 20%;
  margin-right: 40px;
}
@media only screen and (min-width: 480px) {
  .nav-lang {
    width: 15%;
  }
}
.nav-lang ul {
  display: flex;
  justify-content: space-between;
}
.nav-lang ul li a {
  text-transform: uppercase;
  font-weight: 300;
}
.nav-lang ul li a.active {
  font-weight: 600;
}
@media only screen and (min-width: 480px) {
  .nav-lang {
    width: 12%;
  }
}
@media only screen and (min-width: 1000px) {
  .nav-lang {
    width: 5%;
  }
}

.social {
  width: 66%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
.social a:hover {
  color: #565656;
}

.btn-reel {
  width: 100%;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
  margin: 35px 0 30px 0;
  position: relative;
  display: block;
  max-width: 1150px;
  padding: 0px 40px 0;
}
@media only screen and (min-width: 1000px) {
  .btn-reel {
    margin: 0 auto 35px auto;
  }
}
.btn-reel::before {
  content: "";
  position: absolute;
  right: 112px;
  width: 37px;
  height: 1px;
  background: black;
  top: 9px;
}
.btn-reel:hover {
  color: #565656;
}
.btn-reel:hover::before {
  content: "";
  background: #565656;
}

.dropdown {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 480px) {
  .dropdown {
    width: calc(90% - 200px);
    margin-left: 0px;
    padding: 0;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) {
  .dropdown {
    width: calc(65% - 200px);
    margin-left: 0px;
    padding: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .dropdown {
    width: calc(48% - 200px);
    margin-left: 129px;
    display: none;
  }
}
@media only screen and (min-width: 1600px) {
  .dropdown {
    width: calc(48% - 200px);
    margin-left: 89px;
  }
}
.dropdown:hover .btn-group .dropdown-menu {
  display: block;
}
.dropdown .btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: solid thin #E1E1E1;
  padding-right: 10px;
}
.dropdown .btn-group .btn {
  font-size: 15px;
  font-weight: 400;
  border: 0;
  padding: 6px 12px;
  user-select: none;
  background-color: transparent;
}
.dropdown .btn-group .caret {
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown .btn-group .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  background-color: #fff;
}
.dropdown .btn-group .dropdown-menu li a {
  font-size: 15px;
  font-weight: 400;
  padding: 15px 20px;
  display: block;
  color: #333;
  white-space: nowrap;
}
.dropdown .btn-group .dropdown-menu li a:hover {
  color: #ED1845;
}

.container::after {
  clear: both;
  content: " ";
  display: block;
}
.container [data-span] {
  float: left;
  text-align: right;
  margin: 0 gutter()/2;
  box-sizing: border-box;
}
.container [data-span="4"] {
  width: span(12);
}
@media only screen and (min-width: 480px) {
  .container [data-span="1"] {
    text-align: center;
    width: span(1);
  }
  .container [data-span="2"] {
    text-align: center;
    width: span(2);
  }
  .container [data-span="3"] {
    text-align: center;
    width: span(3);
  }
  .container [data-span="4"] {
    text-align: center;
    width: span(4);
  }
  .container [data-span="5"] {
    text-align: center;
    width: span(5);
  }
  .container [data-span="6"] {
    text-align: center;
    width: span(6);
  }
  .container [data-span="7"] {
    text-align: center;
    width: span(7);
  }
  .container [data-span="8"] {
    text-align: center;
    width: span(8);
  }
  .container [data-span="9"] {
    text-align: center;
    width: span(9);
  }
  .container [data-span="10"] {
    text-align: center;
    width: span(10);
  }
  .container [data-span="11"] {
    text-align: center;
    width: span(11);
  }
  .container [data-span="12"] {
    text-align: center;
    width: span(12);
  }
}
.cont-title {
  padding: 20px 40px;
  max-width: 1150px;
  margin: 0 auto;
}
.cont-title h2 {
  color: #ED1845;
}

.container.sponsors {
  padding: 50px 0;
  background: #dedede;
  background: -moz-linear-gradient(-75deg, #dedede 0%, #ffffff 100%);
  background: -webkit-linear-gradient(-75deg, #dedede 0%, #ffffff 100%);
  background: linear-gradient(135deg, #dedede 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#dedede", endColorstr="#ffffff",GradientType=1 );
}
.container.sponsors .sponsors-items {
  display: flex;
  flex-wrap: wrap;
}
.container.sponsors .sponsors-items .sponsor-item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container.sponsors .sponsors-items .sponsor-item img {
  max-width: 80%;
}
@media only screen and (min-width: 480px) {
  .container.sponsors {
    padding-top: 100px;
  }
  .container.sponsors .sponsors-items .sponsor-item {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  .container.sponsors .sponsors-items .sponsor-item img {
    max-width: 80%;
  }
}

.container.info-home-container > article {
  width: 100%;
}
.container.info-home-container > article .info-container > ul {
  margin: 0;
  padding: 40px 40px 0;
  display: none;
  flex-wrap: wrap;
}
@media only screen and (min-width: 480px) {
  .container.info-home-container > article .info-container > ul {
    padding: 0;
  }
}
@media only screen and (min-width: 1000px) {
  .container.info-home-container > article .info-container > ul {
    display: flex;
  }
}
.container.info-home-container > article .info-container > ul > li {
  list-style-type: none;
}
.container.info-home-container > article .info-container > ul > li a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 25px 10px 0;
  display: block;
}
@media only screen and (min-width: 480px) {
  .container.info-home-container > article .info-container > ul > li a {
    padding: 0;
  }
}
.container.info-home-container > article .info-container > ul > li a:hover {
  color: #565656;
}
.container.info-home-container > article .info-container .btn-reel {
  width: auto;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
  margin: 20px 40px 30px;
  position: relative;
  display: block;
  max-width: 1150px;
  padding: 0px;
}
@media only screen and (min-width: 480px) {
  .container.info-home-container > article .info-container .btn-reel {
    margin: 0;
  }
}
.container.info-home-container > article .info-container .btn-reel::before {
  content: "";
  position: absolute;
  right: 70px;
  width: 37px;
  height: 1px;
  background: black;
  top: 9px;
}
@media only screen and (min-width: 480px) {
  .container.info-home-container > article .info-container {
    padding: 30px 40px;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container.info-home-container > article .info-container > ul {
    margin-left: 0;
    width: calc(100% - 90px);
    margin-top: 0px;
    flex-wrap: wrap;
  }
  .container.info-home-container > article .info-container > ul > li {
    width: auto;
    list-style-type: none;
    box-sizing: border-box;
    padding: 0px 19px 0 0;
  }
  .container.info-home-container > article .info-container > ul > li a {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
  }
}
@media only screen and (min-width: 1000px) {
  .container.info-home-container > article .info-container {
    padding: 20px 40px;
    max-width: 1150px;
  }
  .container.info-home-container > article .info-container > ul {
    width: 100%;
    margin-top: 0;
  }
}

.container.showreel-container.skew {
  transform: translateZ(1px) skewY(0deg);
  transition: all 300ms;
}
.container.showreel-container h1 {
  font-size: 45px;
  color: #565656;
  margin: 0 10px;
  display: none;
}
.container.showreel-container .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.container.showreel-container .overlay .data-table {
  height: 100%;
  display: table;
  z-index: 1;
  margin: 0 auto;
  opacity: 1;
}
.container.showreel-container .overlay .data-table .data-cell {
  display: table-cell;
  vertical-align: middle;
  transition: all 500ms;
  transform: translateZ(1px) skewY(0deg) scale(1);
}
.container.showreel-container .overlay .data-table .data-cell .button {
  width: 50px;
  height: 65px;
  margin: 0 auto;
  opacity: 0.5;
  font-size: 60px;
}
.container.showreel-container .overlay .data-table .data-cell .button span {
  width: 50px;
  height: 65px;
  color: #fff;
}
.container.showreel-container > article {
  position: relative;
  overflow: hidden;
}
.container.showreel-container > article .aspect-image {
  opacity: 1;
}
.container.showreel-container > article .showreel-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin-top: -10%;
  width: 100%;
}
.container.showreel-container > article .showreel-background video {
  width: 100%;
  transform: translateZ(1px) skewY(0deg) scale(1);
}
.container.showreel-container > article .showreel-background img {
  width: 100%;
}
.container.showreel-container:hover .overlay .data-table .data-cell {
  transform: translateZ(1px) translateY(-5px) skewY(0deg) scale(1.2);
}
.container.slides {
  display: none;
}
.container.slides .slick-arrow {
  display: none !important;
}
.container.slides .slick-slide .slide-content.slide-1 .slide-content-absolute article p, .container.slides .slick-slide .slide-content.slide-2 .slide-content-absolute article p, .container.slides .slick-slide .slide-content:not(.slide-0) .slide-content-absolute article p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
@media only screen and (min-width: 480px) {
  .container.slides .slick-slide .slide-content.slide-1 .slide-content-absolute article p, .container.slides .slick-slide .slide-content.slide-2 .slide-content-absolute article p, .container.slides .slick-slide .slide-content:not(.slide-0) .slide-content-absolute article p {
    font-size: 35px;
  }
}
@media only screen and (min-width: 1025px) {
  .container.slides .slick-slide .slide-content.slide-1 .slide-content-absolute article p, .container.slides .slick-slide .slide-content.slide-2 .slide-content-absolute article p, .container.slides .slick-slide .slide-content:not(.slide-0) .slide-content-absolute article p {
    font-size: 45px;
  }
}
.container.slides .slick-slide .slide-content.slide-3 .slide-content-absolute article h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}
@media only screen and (min-width: 480px) {
  .container.slides .slick-slide .slide-content.slide-3 .slide-content-absolute article h1 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 1025px) {
  .container.slides .slick-slide .slide-content.slide-3 .slide-content-absolute article h1 {
    font-size: 45px;
  }
}
.container.slides .slide-content {
  position: relative;
}
.container.slides .slide-content .aspect-image img {
  width: 100%;
}
.container.slides .slide-content .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container.slides .slide-content .slider-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container.slides .slide-content .slider-background img, .container.slides .slide-content .slider-background video {
  width: 100%;
}
.container.slides .slide-content .slide-content-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container.slides .slide-content .slide-content-absolute article {
  padding: 10px;
  width: 100%;
}
.container.slides .slide-content .slide-content-absolute article h1 {
  color: #fff;
}
.container.slides .slide-content .slide-content-absolute article p {
  font-size: 10px;
  color: #fff;
}
.container.slides .slide-content .slide-content-absolute article .slide-index {
  color: #fff;
  width: 75px;
  padding: 10px 0 5px 0;
  border-bottom: 3px solid #fff;
  font-size: 9px;
  position: absolute;
  bottom: 10px;
}
.container.slides .slick-dots {
  bottom: 50px;
}
@media only screen and (min-width: 480px) {
  .container.slides {
    display: block;
  }
  .container.slides .slide-content .slide-content-absolute {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
  .container.slides .slide-content .slide-content-absolute article {
    width: calc(100% - 100px);
    margin-right: 100px;
  }
  .container.slides .slide-content .slide-content-absolute article p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 950px) {
  .container.slides .slide-content .slide-content-absolute article {
    width: calc(50% - 100px);
  }
}
@media only screen and (min-width: 1600px) {
  .container.slides .slide-content .slide-content-absolute article {
    width: calc(50% - 400px);
    margin-right: 400px;
  }
  .container.slides .slide-content .slide-content-absolute article p {
    font-size: 20px;
  }
}

.container.featured.skew {
  transform: translateZ(1px) skewY(0deg);
  overflow: hidden;
}
.container.featured .projects-container > .project {
  position: relative;
  margin: 0 0;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
}
.container.featured .projects-container > .project.home_noticias {
  width: 100%;
}
.container.featured .projects-container > .project.home_noticias .aspect-image {
  padding-bottom: 100px;
}
@media (min-width: 480px) {
  .container.featured .projects-container > .project.home_noticias .aspect-image {
    padding-bottom: 0;
  }
}
.container.featured .projects-container > .project .aspect-image {
  opacity: 0;
}
.container.featured .projects-container > .project .overlay {
  position: absolute;
  height: 100%;
  transform: scale(1);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: all 100ms;
  opacity: 0;
}
.container.featured .projects-container > .project .overlay .data-table {
  height: 100%;
  display: table;
  z-index: 10;
  margin: 0 auto;
  opacity: 1;
}
.container.featured .projects-container > .project .overlay .data-table .data-cell {
  display: table-cell;
  vertical-align: middle;
  transition: all 500ms;
}
.container.featured .projects-container > .project .overlay .data-table .data-cell .button {
  width: 50px;
  height: 65px;
  margin: 0 auto;
  opacity: 1;
  font-size: 40px;
}
.container.featured .projects-container > .project .overlay .data-table .data-cell .button span {
  width: 50px;
  height: 65px;
  color: #fff;
}
.container.featured .projects-container > .project .project-container {
  overflow: hidden;
  position: relative;
}
.container.featured .projects-container > .project .project-container .background-container {
  position: absolute;
  width: 101%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.container.featured .projects-container > .project .project-container .background-container img {
  opacity: 1;
  width: 100%;
  transition: all 300ms;
}
.container.featured .projects-container > .project .project-text {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0;
  z-index: 1;
  opacity: 1;
  padding: 0;
  text-align: left;
  transform: scale(1);
  transition: all 300ms;
  height: 100%;
  box-sizing: border-box;
  background: black; /* Old browsers */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#a6000000", GradientType=0); /* IE6-9 */
  display: flex;
  align-items: flex-end;
}
.container.featured .projects-container > .project .project-text h3 {
  bottom: 0;
  width: 100%;
  min-height: 50px;
  color: #fff;
  font-size: 45px;
  font-weight: 100;
  box-sizing: border-box;
  margin: 0;
  padding: 40px;
}
@media only screen and (min-width: 1000px) {
  .container.featured .projects-container > .project .project-text h3 {
    width: 50%;
  }
}
.container.featured .projects-container > .project .project-text.project-text_home_noticia .descripcion_home_noticia {
  color: white;
  font-weight: 300;
  padding: 10px;
  display: none;
  max-width: 70%;
  height: 100%;
  font-size: 12px;
  overflow: auto;
}
@media (min-width: 480px) {
  .container.featured .projects-container > .project .project-text.project-text_home_noticia .descripcion_home_noticia {
    padding: 30px;
    max-width: 50%;
    height: unset;
    font-size: 16px;
    overflow: unset;
    display: block;
  }
}
.container.featured .projects-container > .project:hover .overlay {
  opacity: 1;
}
.container.featured .projects-container > .project:hover .project-text {
  color: #000;
  transform: scale(1);
  opacity: 1;
  top: 0;
  display: flex;
  align-items: flex-end;
}
@media only screen and (min-width: 480px) {
  .container.featured .projects-container > .project {
    width: 50%;
  }
  .container.featured .projects-container > .project .project-text {
    transform: scale(1);
    min-height: 40px;
  }
}
@media only screen and (min-width: 1000px) {
  .container.featured .projects-container > .project .project-text {
    transform: scale(1);
    min-height: 50px;
  }
}
.player-container {
  position: fixed;
  z-index: 101;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-container article {
  width: 100%;
}
.player-container .plyr {
  max-height: 100vh;
}
.player-container .content {
  background: #565656;
}
.player-container header {
  height: 10vh;
  color: #fff;
  position: fixed;
  z-index: 1000000;
  width: 100%;
  top: 0;
}
.player-container header .close-icon {
  position: absolute;
  right: 50px;
  top: 4vh;
  cursor: pointer;
  font-size: 30px;
}
.player-container .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(26, 175, 255);
  width: 80px;
  height: 80px;
  top: 50%;
}
@media screen and (min-width: 1900px) {
  .player-container .plyr--full-ui.plyr--video .plyr__control--overlaid {
    top: 57%;
  }
}
.player-container .plyr--full-ui.plyr--video .plyr__control--overlaid svg {
  height: 30px;
  left: auto;
  position: relative;
  width: 30px;
}

.videocontainer-appear {
  transform: scaleY(0);
  transition: all 2000ms ease-in;
}

.videocontainer-appear.videocontainer-appear-active {
  transform: scaleY(1);
}

.videocontaineriframe-appear {
  opacity: 0;
  transition-delay: 5s;
}

.videocontaineriframe-appear.videocontaineriframe-appear-active {
  opacity: 1;
}

.bloques {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media only screen and (min-width: 480px) {
  .bloques {
    width: 50%;
  }
}
.bloques.solo-txt .bloque:nth-child(2) {
  display: none;
}
.bloques.solo-txt .bloque.noticias {
  height: 100%;
}
.bloques.solo-redes {
  width: 100%;
}
.bloques.solo-redes .bloque:nth-child(2) {
  position: relative;
}
.bloques.solo-redes .bloque.noticias {
  display: none;
}
.bloques.solo-redes .aspect-image {
  display: none;
}
.bloques.solo-redes .project-text {
  position: relative;
}
.bloques .aspect-image {
  opacity: 0;
  width: 100%;
}
.bloques .project-text {
  position: absolute;
  width: 100%;
  top: 0%;
  left: 0;
  z-index: 1;
  opacity: 1;
  padding: 0;
  text-align: left;
  transform: scale(1);
  transition: all 300ms;
  height: 100%;
  box-sizing: border-box;
}
.bloques .bloque {
  width: 100%;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.bloques .bloque:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 0;
}
.bloques .bloque .rojo {
  width: 100%;
  background: #c21037;
  background: -moz-linear-gradient(left, #c21037 0%, #ef5a5e 100%);
  background: -webkit-linear-gradient(left, #c21037 0%, #ef5a5e 100%);
  background: linear-gradient(to right, #c21037 0%, #ef5a5e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#c21037", endColorstr="#ef5a5e",GradientType=1 );
  font-size: 25px;
  min-height: 242px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bloques .bloque .rojo a {
  padding-right: 25px;
}
.bloques .bloque .rojo a:last-child {
  padding-right: 0;
}
.bloques .bloque .rojo a:hover {
  color: #F7F7F7;
}
@media only screen and (min-width: 480px) {
  .bloques .bloque .rojo {
    width: 50%;
  }
}
.bloques .bloque .violeta {
  width: 100%;
  background: #6A3795;
  font-size: 20px;
  font-weight: 600;
  min-height: 242px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 480px) {
  .bloques .bloque .violeta {
    width: 50%;
  }
}
@media screen and (min-width: 1320px) {
  .bloques .bloque .violeta {
    font-size: 26px;
  }
}
.bloques .bloque.noticias {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  color: black;
  padding: 0 30px;
  height: 47%;
}
@media only screen and (min-width: 1000px) {
  .bloques .bloque.noticias {
    font-size: 24px;
    line-height: 26px;
    padding: 0 65px;
  }
}
.bloques .bloque.noticias p {
  margin: 0;
}
.bloques .bloque.noticias b {
  font-weight: 500;
}

.page-header {
  color: #000;
  padding-left: 10px;
  font-size: 20px;
}

.container.tag.skew {
  transform: translateZ(1px) skewY(0deg);
  overflow: hidden;
}
.container.tag h1 {
  color: #565656;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-size: 18px;
}
.container.tag .projects-container > .project {
  margin: 0 0;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
}
.container.tag .projects-container > .project .aspect-image {
  opacity: 0;
  min-height: 50px;
  transform: translateZ(1px) skewY(0deg) scale(1);
}
.container.tag .projects-container > .project .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: all 100ms;
  opacity: 0;
}
.container.tag .projects-container > .project .project-container {
  overflow: hidden;
  position: relative;
}
.container.tag .projects-container > .project .project-container .background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.container.tag .projects-container > .project .project-container .background-container img {
  opacity: 1;
  width: 100%;
  transition: all 300ms;
}
.container.tag .projects-container > .project .project-text {
  position: absolute;
  width: 100%;
  opacity: 0;
  bottom: 0;
  lef: 0;
  z-index: 1;
  background: #ed1845;
  color: #fff;
  text-align: center;
  margin-top: 0%;
  transform: scale(1);
  transition: all 300ms;
  min-height: 70px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
}
.container.tag .projects-container > .project .project-text h3 {
  font-size: 15px;
  font-weight: 100;
  text-transform: uppercase;
}
.container.tag .projects-container > .project .project-text p {
  font-size: 12px;
  font-weight: 100;
  padding: 0px 45px 10px;
}
.container.tag .projects-container > .project:hover .overlay {
  opacity: 1;
}
.container.tag .projects-container > .project:hover .background-container img {
  transform: skewY(0deg) scale(1.05);
}
.container.tag .projects-container > .project:hover .project-text {
  background: #ed1845;
  color: #fff;
  transform: scale(1.4);
  opacity: 1;
}
@media only screen and (min-width: 480px) {
  .container.tag h1 {
    font-size: 45px;
  }
  .container.tag .projects-container > .project {
    width: 50%;
  }
  .container.tag .projects-container > .project .project-text p {
    padding: 0px 100px 10px;
  }
}
@media only screen and (min-width: 1000px) {
  .container.tag.skew {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1600px) {
  .container.tag .projects-container > .project {
    width: 33.33%;
  }
}

.black-container article {
  position: relative;
}
.black-container article .aspect-image {
  display: none;
}
@media only screen and (min-width: 1000px) {
  .black-container article .aspect-image {
    display: block;
  }
}
.black-container article header {
  position: relative;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
  color: #ED1845;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(222, 222, 222) 100%);
  background: -webkit-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(222, 222, 222) 100%);
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(222, 222, 222) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#dedede",GradientType=1 );
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
@media only screen and (min-width: 1000px) {
  .black-container article header {
    position: absolute;
  }
}
.black-container article header.with-background {
  justify-content: flex-start;
  color: white;
}
.black-container article header.with-background .cont-header {
  max-width: 600px;
}
.black-container article header .cont-header {
  padding: 0;
  max-width: 960px;
  width: 100%;
}
@media only screen and (min-width: 1000px) {
  .black-container article header .cont-header {
    max-width: 820px;
  }
}
@media only screen and (min-width: 1366px) {
  .black-container article header .cont-header {
    max-width: 815px;
  }
}
@media only screen and (min-width: 1600px) {
  .black-container article header .cont-header {
    max-width: 815px;
  }
}
.black-container article header .cont-header h1 {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  padding: 0 0 40px;
}
@media only screen and (min-width: 480px) {
  .black-container article header .cont-header h1 {
    width: 100%;
  }
}
.black-container article header .cont-header p {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
}
@media only screen and (min-width: 480px) {
  .black-container article header .cont-header p {
    width: 100%;
  }
}
.black-container article header .cont-header a {
  background: #ED1845;
  color: white;
  font-size: 15px;
  padding: 5px 10px;
  margin-top: 20px;
  display: table;
}
.black-container article header .cont-header a:hover {
  background: black;
}

section.work-single {
  width: 100%;
  max-height: 100%;
  z-index: 100;
  padding-bottom: 10px;
}
@media only screen and (min-width: 480px) {
  section.work-single .info-container {
    padding: 30px 40px;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  section.work-single .info-container > ul {
    margin-left: 0;
    width: calc(100% - 90px);
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  section.work-single .info-container > ul > li {
    width: auto;
    list-style-type: none;
    box-sizing: border-box;
    padding: 0px 19px 0 0;
  }
  section.work-single .info-container > ul > li a {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
  }
}
@media only screen and (min-width: 1000px) {
  section.work-single .info-container {
    padding: 20px 40px;
    max-width: 1150px;
  }
  section.work-single .info-container > ul {
    width: calc(100% - 200px);
    margin-top: 0;
  }
}
section.work-single .info-container > ul {
  margin: 0;
  padding: 40px 40px 0;
  display: none;
  flex-wrap: wrap;
}
@media only screen and (min-width: 480px) {
  section.work-single .info-container > ul {
    margin-left: 0;
    width: calc(100% - 90px);
    padding: 0;
  }
}
@media only screen and (min-width: 1366px) {
  section.work-single .info-container > ul {
    display: flex;
  }
}
@media only screen and (min-width: 1000px) {
  section.work-single .info-container > ul {
    width: calc(100% - 200px);
    display: flex;
  }
}
section.work-single .info-container > ul > li {
  list-style-type: none;
}
section.work-single .info-container > ul > li a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 25px 10px 0;
  display: block;
}
@media only screen and (min-width: 480px) {
  section.work-single .info-container > ul > li a {
    padding: 0;
  }
}
section.work-single .info-container > ul > li a:hover {
  color: #565656;
}
section.work-single .info-container .btn-reel {
  width: auto;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
  margin: 20px 40px 30px;
  position: relative;
  display: block;
  max-width: 1150px;
  padding: 0px;
}
@media only screen and (min-width: 480px) {
  section.work-single .info-container .btn-reel {
    margin: 0;
  }
}
section.work-single .info-container .btn-reel::before {
  content: "";
  position: absolute;
  right: 70px;
  width: 37px;
  height: 1px;
  background: black;
  top: 9px;
}
section.work-single header {
  position: fixed;
  height: 10vh;
  position: relative;
  height: 100%;
  left: 0;
  z-index: 2;
  background: #565656;
  color: #fff;
}
section.work-single header .close-icon {
  position: fixed;
  z-index: 2;
  right: 20px;
  top: 2vh;
  cursor: pointer;
  font-size: 30px;
}
section.work-single .hero-content {
  position: relative;
  left: 0;
  width: 100%;
  z-index: 1;
}
section.work-single .hero-content img {
  display: block;
}
section.work-single .content {
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  text-align: left;
}
section.work-single .content .description {
  padding: 10px 40px;
  font-weight: 100;
  max-width: 480px;
  margin: 0 auto;
}
@media only screen and (min-width: 480px) {
  section.work-single .content .description {
    max-width: 960px;
  }
}
@media only screen and (min-width: 1000px) {
  section.work-single .content .description {
    max-width: 1067px;
    padding: 20px 40px 110px;
    width: calc(100% - 260px);
  }
}
@media only screen and (min-width: 1366px) {
  section.work-single .content .description {
    max-width: 1067px;
    width: 100%;
    padding: 20px 0 110px;
  }
}
section.work-single .content .description .ficha-tecnica {
  color: #ED1845;
}
section.work-single .content .description .ficha-tecnica h3 {
  font-size: 30px;
  font-weight: 700;
  color: #ED1845;
  margin: 0;
  padding: 40px 0;
}
section.work-single .content .description .ficha-tecnica .subtitle-single-work {
  padding: 0;
  margin: 0;
}
section.work-single .content .description .ficha-tecnica .subtitle-single-work div {
  display: inline-block;
  margin: 4px;
  font-weight: 300;
  opacity: 0.8;
  padding: 4px;
}
section.work-single .content .description .ficha-tecnica .flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.work-single .content .description .ficha-tecnica .flex > div {
  width: 100%;
}
@media only screen and (min-width: 480px) {
  section.work-single .content .description .ficha-tecnica .flex > div {
    width: 48%;
  }
}
section.work-single .content .description .ficha-tecnica p {
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 20px;
}
section.work-single .content .assets {
  display: flex;
  flex-wrap: wrap;
}
section.work-single .content .assets .asset {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
section.work-single .content .assets .asset .ficha-tecnica {
  padding: 10px 20px;
  background: #565656;
}
section.work-single .content .assets .asset.image .thumbnail {
  width: 50%;
}
section.work-single .content .assets .asset.image .thumbnail img {
  display: block;
}
section.work-single .content p {
  line-height: 20px;
}
@media only screen and (min-width: 480px) {
  section.work-single header {
    height: 10vh;
  }
  section.work-single header .close-icon {
    top: 4vh;
    right: 50px;
  }
  section.work-single .content .assets .asset {
    width: 100%;
    margin: 0;
  }
}
.us-container {
  width: 100%;
}
.us-container .in-frame {
  padding: 40px 40px 0;
  margin: 0 auto;
  max-width: 1150px;
  width: 100%;
}
.us-container .in-frame h4 {
  color: #000;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px 0;
}
.us-container .in-frame .columns .col {
  padding-right: 30px;
}
@media only screen and (min-width: 1000px) {
  .us-container .columns {
    display: flex;
    justify-content: space-around;
  }
  .us-container .columns .col {
    width: 33%;
  }
}

#contact, #message-success, #message-error {
  width: 100%;
}
#contact .in-frame, #message-success .in-frame, #message-error .in-frame {
  padding: 40px 40px 0;
  margin: 0 auto;
  max-width: 1150px;
  width: 100%;
}
#contact .in-frame h1, #message-success .in-frame h1, #message-error .in-frame h1 {
  font-size: 1.5em;
}
#contact .in-frame h4, #message-success .in-frame h4, #message-error .in-frame h4 {
  color: #000;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px 0;
}
#contact .in-frame form > div, #message-success .in-frame form > div, #message-error .in-frame form > div {
  padding-bottom: 15px;
}
#contact .in-frame label, #message-success .in-frame label, #message-error .in-frame label {
  display: block;
  font-weight: 400;
  padding-bottom: 5px;
}
#contact .in-frame label .required, #message-success .in-frame label .required, #message-error .in-frame label .required {
  font-size: 12px;
  vertical-align: top;
}
#contact .in-frame input, #contact .in-frame textarea, #message-success .in-frame input, #message-success .in-frame textarea, #message-error .in-frame input, #message-error .in-frame textarea {
  width: 100%;
  border: solid thin #565656;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
#contact .in-frame input, #message-success .in-frame input, #message-error .in-frame input {
  height: 25px;
}
#contact .in-frame textarea, #message-success .in-frame textarea, #message-error .in-frame textarea {
  resize: none;
}
#contact .in-frame button, #message-success .in-frame button, #message-error .in-frame button {
  border: none;
  background: black;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}
#contact .in-frame button:hover, #message-success .in-frame button:hover, #message-error .in-frame button:hover {
  background: #565656;
}
@media only screen and (min-width: 1000px) {
  #contact .columns, #message-success .columns, #message-error .columns {
    display: flex;
  }
  #contact .columns .col, #message-success .columns .col, #message-error .columns .col {
    width: 33%;
  }
}

.footer {
  color: #565656;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 480px;
  padding: 40px;
  margin: 0 auto;
}
.footer > div {
  width: 100%;
}
.footer > div p {
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
  color: #565656;
  opacity: 1;
  line-height: 1;
}
.footer > div:nth-child(1) {
  width: 100%;
}
.footer > div:nth-child(1) p {
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
  color: #565656;
  opacity: 1;
}
.footer .social {
  font-size: 15px;
  text-align: left;
  padding-top: 15px;
}
.footer .social span {
  margin: 20px 10px 0;
}
.footer .untref-logo {
  margin-top: 40px;
}
.footer .untref-logo img {
  max-width: 100%;
  width: 150px;
}
@media only screen and (min-width: 480px) {
  .footer {
    max-width: 960px;
    margin-top: 110px;
    padding: 0 40px 110px;
  }
  .footer > div {
    width: auto;
  }
  .footer > div:nth-child(1) {
    text-align: left;
    width: 15.5%;
  }
  .footer > div:nth-child(2) {
    padding-top: 22px;
    font-size: 30px;
  }
  .footer > div:nth-child(2) span {
    margin: 0px 10px 0;
  }
  .footer .untref-logo {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1000px) {
  .footer {
    max-width: 1150px;
    padding: 0 40px 110px;
  }
}