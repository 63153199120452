
#contact, #message-success, #message-error {
  width: 100%;
  .in-frame {
    padding: 40px 40px 0;

    margin: 0 auto;
    max-width: 1150px;
    width: 100%;

    h1 {
      font-size: 1.5em;
    }

    h4{
      color:#000;
      font-family: 'Work Sans', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      margin: 10px 0;
    }

    form {
      > div {
        padding-bottom: 15px;
      }
    }
    label {
      display: block;
      font-weight: 400;
      padding-bottom: 5px;

      .required {
        font-size: 12px;
        vertical-align: top;
      }
    }
    input, textarea {
      width: 100%;
      border: solid thin #565656;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
    input {
      height: 25px;
    }
    textarea {
      resize: none;
    }

    button {
      border: none;
      background: black;
      color: white;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      cursor: pointer;

      &:hover {
        background: #565656;
      }
    }

    .columns{
      .col{
        //padding-right:30px;
      }
    }
  }

  @media only screen and (min-width: $largeScreens) {
    .columns {
      display: flex;
      //justify-content: space-around;
      .col {
        width:33%;
      }
    }
  }
}

