// $susy: (
//         'svg-grid-colors': hsl(180, 50%, 80%),
//         'columns': susy-repeat(12),
//         'container-spread': 'wide',
//         'gutters': 0,
//         'spread': 'wide',
// );

section.work-single {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100%;
  max-height: 100%;
  z-index: 100;
  // overflow: scroll;
  padding-bottom: 10px;

  .info-container {

    @media only screen and (min-width: $mediumScreens) {

          padding: 30px 40px;
          max-width: 960px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > ul {
            margin-left: 0;
            width: calc(100% - 90px);
            margin-top: 0px;
            display: flex;
            flex-wrap: wrap;

            > li {
              width: auto;
              list-style-type: none;
              box-sizing: border-box;
              //background: #000;
              padding: 0px 19px 0 0;
              a {
                width: 100%;
                // font-weight: bold;
                box-sizing: border-box;
                height: 100%;

                &:hover {
                  // color: #000;
                }
              }
            }
          }

    }
    @media only screen and (min-width: $largeScreens) {

      padding: 20px 40px;
      max-width: 1150px;

      > ul {
        width: calc(100% - 200px);
        margin-top: 0;
        //margin-left: 129px;
      }

    }

    > ul {
      margin: 0;
      padding: 40px 40px 0;
      display: none;
      flex-wrap: wrap;
      // justify-content: space-between;

      @media only screen and (min-width: $mediumScreens) {
        margin-left: 0;
        width: calc(100% - 90px);
        padding: 0;
      }
      @media only screen and (min-width: 1366px) {
        display: flex;
      }
      @media only screen and (min-width: $largeScreens) {
        width: calc(100% - 200px);
        display: flex;
        //margin-left: 129px;
      }

      > li {
        list-style-type: none;

        a {
          text-decoration: none;
          // color: #fff;
          font-size: 15px;
          font-weight: 400;
          padding: 0px 25px 10px 0;
          display: block;

          @media only screen and (min-width: $mediumScreens) {
            padding: 0;
          }

          &:hover {
            color: $secondaryColor;
          }
        }
      }
    }

    .btn-reel {
      width: auto;
      text-align: right;
      font-size: 15px;
      font-weight: 400;
      margin: 20px 40px 30px;
      position: relative;
      display: block;
      max-width: 1150px;
      padding: 0px;

      @media only screen and (min-width: $mediumScreens) {
        margin: 0;
      }

      @media only screen and (min-width: $largeScreens) {
        // margin: 0 0 30px 0;
      }

      &::before {
        content: '';
        position: absolute;
        right: 70px;
        width: 37px;
        height: 1px;
        background: black;
        top: 9px;
      }

    }
  }

  header {
    position: fixed;
    height: 10vh;
    position: relative;
    //top:10px;
    height: 100%;

    left: 0;
    z-index: 2;
    background: $secondaryColor;
    color: #fff;
    .close-icon {
      position: fixed;
      z-index: 2;
      right: 20px;
      top: 2vh;
      cursor: pointer;

      font-size: 30px;
    }

  }
  .hero-content {
    position: relative;
    //margin-top: 10vh;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      display: block;
    }
  }
  .content {
    position: relative;
    z-index: 10;
    // background: $secondaryColor;
    // color: #fff;
    box-sizing: border-box;
    text-align: left;
    .description {
      // text-align: center;
      padding: 10px 40px;
      font-weight: 100;
      max-width: 480px;
      margin: 0 auto;

      @media only screen and (min-width: $mediumScreens) {
        max-width: 960px;
      }
      @media only screen and (min-width: $largeScreens) {
        max-width: 1067px;
        padding: 20px 40px 110px;
        width: calc(100% - 260px);
      }
      @media only screen and (min-width: 1366px) {
        max-width: 1067px;
        width: 100%;
        padding: 20px 0 110px;
      }

      .ficha-tecnica {
        color: #ED1845;

        h3 {
          font-size: 30px;
          font-weight: 700;
          color: #ED1845;
          // text-transform: uppercase;
          margin: 0;
          padding: 40px 0;
        }
        .subtitle-single-work {
          padding: 0;
          margin: 0;
          div {
            display: inline-block;
            margin: 4px;
            // border-radius: 4px;
            font-weight: 300;
            opacity: .8;
            //background: #a01a2a;
            padding: 4px;;


          }
        }

        .flex {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > div {
            width: 100%;

            @media only screen and (min-width: $mediumScreens) {
              width: 48%;
            }
          }
        }
        p {
          font-weight: 300;
          font-size: 14px;
          margin: 0 0 20px;
        }
      }
    }
    .assets {
      display: flex;
      flex-wrap: wrap;
      .asset {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .ficha-tecnica {
          padding: 10px 20px;
          background: $secondaryColor;
        }
        &.image {
          .thumbnail {
            width: 50%;

            img {
              display: block;
            }
          }

        }
      }
    }

    p {
      line-height: 20px;
    }
  }

  @media only screen and (min-width: $mediumScreens) {
    header {
      height: 10vh;
      .close-icon {
        top: 4vh;
        right: 50px;
      }
    }
    .hero-content {
      //margin-top:10vh
    }
    .content {
      .description {
        // padding: 10px 30%;
      }
      .assets {

        .asset {
          width: 100%;
          margin: 0;
          &.image {
            img {
              //display: none;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: $largeScreens) {

  }
}

.single-header {

}
