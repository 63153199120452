
.us-container {
  width: 100%;
  .in-frame {
    padding: 40px 40px 0;

    margin: 0 auto;
    max-width: 1150px;
    width: 100%;

    h4{
      color:#000;
      font-family: 'Work Sans', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      margin: 10px 0;


    }
    .columns{
      .col{
        padding-right:30px;
      }
    }
  }

  @media only screen and (min-width: $largeScreens) {
    .columns {
      display: flex;
      justify-content: space-around;
      .col {
        width:33%;
      }
    }
  }
}

