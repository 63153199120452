//$susy: (
//        'svg-grid-colors': hsl(180, 50%, 80%),
//        'columns': susy-repeat(36),
//        'container-spread': 'narrow',
//        'gutters': 0,
//        'spread': 'narrow',
//);


.header{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;

  .logo{
    width: 100px;
    margin: -3px 0 0 -11px;

  }
  nav{
    //flex-basis: calc(100% - 200px);
    flex-basis: calc(100% - 0px);
    margin: 20px 0 20px 0;

    @media only screen and (min-width: $largeScreens) {
      flex-basis: calc(100% - 366px);
      margin: 0px;
      height: 95px;
    }
  }

  @media only screen and (min-width: $mediumScreens) {
    max-width: 960px;
  }
  @media only screen and (min-width: $largeScreens) {
    max-width: 1150px;
    padding: 40px 40px 0;
  }
}
nav {
  // display: none;
  height: auto;
  // overflow: hidden;
  //margin-bottom: 100px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    @media only screen and (min-width: $mediumScreens) {
      justify-content: normal;
    }

    li {
      text-align: center;
      //padding: 10px 10px 10px 10px;
      box-sizing: border-box;
      float: right;
      padding: 0px;
      list-style-type: none;
      //border-top: 4px solid $secondaryColor;

      @media only screen and (min-width: $mediumScreens) {
        padding: 2px 10px 5px 10px;
      }

      &:first-child {
        padding: 0px;

        @media only screen and (min-width: $mediumScreens) {
          padding: 2px 10px 5px 0px;
        }
        @media only screen and (min-width: $largeScreens) {
          padding: 2px 10px 5px 10px;
        }
      }

      &.ochotreinta {

        a {
          color: #EE3649;
        }
      }

      a,span {
        font-size: 13px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        color: #000;
        cursor: pointer;

        // &:visited {
        //   color: $secondaryColor;
        // }
        &:hover {
          color: $secondaryColor;
        }
        &.active {
          & + & {
            //border-top: 8px solid $secondaryColor;
          }

        }

      }
      &.active {
        //border-top: 8px solid $secondaryColor;
        //padding: 10px 10px 10px 10px;
      }
      &:hover {
        //border-top: 8px solid $secondaryColor;
        //padding: 10px 10px 10px 10px;
      }

    }
  }
  @media only screen and (min-width: $largeScreens) {
    display: block;
  }
}

.nav-lang {
  width: 20%;
  margin-right: 40px;

  @media only screen and (min-width: $mediumScreens) {
    width: 15%;
  }

   ul {
     display: flex;
     justify-content: space-between;

     li {

       a {
          text-transform: uppercase;
          font-weight: 300;

          &.active {
            font-weight: 600;
            // color: $secondaryColor
          }
       }
     }
   }

   @media only screen and (min-width: $mediumScreens) {
     width: 12%;
   }

   @media only screen and (min-width: $largeScreens) {
     width: 5%;
   }
}

.social {
  width: 66%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  //@media only screen and (min-width: $mediumScreens) {
  //  width: 20%;
  //}
  //
  //@media only screen and (min-width: $largeScreens) {
  //  width: 9%;
  //}

  a {

    &:hover {
      color: $secondaryColor;
    }
  }
}

.btn-reel {
  width: 100%;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
  margin: 35px 0 30px 0;
  position: relative;
  display: block;
  max-width: 1150px;
  padding: 0px 40px 0;

  @media only screen and (min-width: $largeScreens) {
    margin: 0 auto 35px auto;
  }

  &::before {
    content: '';
    position: absolute;
    right: 112px;
    width: 37px;
    height: 1px;
    background: black;
    top: 9px;
  }

  &:hover {
    color: $secondaryColor;

    &::before {
      content: '';
      background: $secondaryColor;
    }
  }
}

.dropdown {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 20px;
  // margin-left: 40px;

  @media only screen and (min-width: $mediumScreens) {
    width: calc(90% - 200px);
    margin-left: 0px;
    padding: 0;
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 768px) {
    width: calc(65% - 200px);
    margin-left: 0px;
    padding: 0;
  }

  @media only screen and (min-width: $largeScreens) {
    width: calc(48% - 200px);
    margin-left: 129px;
    display: none;
  }
  @media only screen and (min-width: $bigScreens) {
    width: calc(48% - 200px);
    margin-left: 89px;
  }

  // @media only screen and (min-width: $largeScreens) {
  //   display: none;
  // }

  &:hover {

    .btn-group {

      .dropdown-menu {
        display: block;
      }
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: solid thin #E1E1E1;
    padding-right: 10px;

    .btn {
      // display: inline-block;
      // margin-bottom: 0;
      font-size: 15px;
      font-weight: 400;
      // text-align: center;
      // vertical-align: middle;
      // cursor: pointer;
      // background-image: none;
      border: 0;
      // white-space: nowrap;
      padding: 6px 12px;
      // font-size: 14px;
      // line-height: 1.42857143;
      // border-radius: 4px;
      user-select: none;
      background-color: transparent;
    }

    .caret {
      // display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      // float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      // list-style: none;
      // font-size: 14px;
      background-color: #fff;
      // border: 1px solid #ccc;
      // border: 1px solid rgba(0,0,0,.15);
      // border-radius: 4px;
      // box-shadow: 0 6px 12px rgba(0,0,0,.175);
      // background-clip: padding-box;

      li {

        a {
          font-size: 15px;
          font-weight: 400;
          // padding: 0px 25px 10px 0;
          padding: 15px 20px;
          // text-align: center;
          // border-bottom: thin solid rgba(255, 255, 255, 0.5);

          display: block;
          // clear: both;
          // font-weight: 400;
          // line-height: 1.42857143;
          color: #333;
          white-space: nowrap;

          &:hover {
            color: $rojo;
          }
        }
      }
    }
  }
}
