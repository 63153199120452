// $susy: (
//         'svg-grid-colors': hsl(180, 50%, 80%),
//         'columns': susy-repeat(12),
//         'container-spread': 'narrow',
//         'gutters': 0,
//         'spread': 'narrow',
// );
.App {

}

.sections {

}

.section-master {

}

.cont-title {
  padding: 20px 40px;
  max-width: 1150px;
  margin: 0 auto;

  h2 {
    color: $rojo;
  }
}

.container.sponsors {
  padding: 50px 0;
  background: #dedede;
  background: -moz-linear-gradient(-75deg, #dedede 0%, #ffffff 100%);
  background: -webkit-linear-gradient(-75deg, #dedede 0%,#ffffff 100%);
  background: linear-gradient(135deg, #dedede 0%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#ffffff',GradientType=1 );

  .sponsors-items {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    .sponsor-item {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 80%;
      }
    }
  }
  @media only screen and (min-width: $mediumScreens) {
    padding-top: 100px;

    .sponsors-items {
      .sponsor-item {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        img {
          max-width: 80%;
        }
      }
    }

  }
}

.container.info-home-container {
  > article {
    // background: $secondaryColor;

    width: 100%;

    // text-align: center;
    // text-transform: uppercase;
    .info-container {

      > ul {
        margin: 0;
        padding: 40px 40px 0;
        display: none;
        flex-wrap: wrap;
        // justify-content: space-between;

        @media only screen and (min-width: $mediumScreens) {
          padding: 0;
        }
        @media only screen and (min-width: $largeScreens) {
          display: flex;
        }

        > li {
          list-style-type: none;

          a {
            text-decoration: none;
            // color: #fff;
            font-size: 15px;
            font-weight: 400;
            padding: 0px 25px 10px 0;
            display: block;

            @media only screen and (min-width: $mediumScreens) {
              padding: 0;
            }

            &:hover {
              color: $secondaryColor;
            }
          }
        }
      }

      .btn-reel {
        width: auto;
        text-align: right;
        font-size: 15px;
        font-weight: 400;
        margin: 20px 40px 30px;
        position: relative;
        display: block;
        max-width: 1150px;
        padding: 0px;

        @media only screen and (min-width: $mediumScreens) {
          margin: 0;
        }

        @media only screen and (min-width: $largeScreens) {
          // margin: 0 0 30px 0;
        }

        &::before {
          content: '';
          position: absolute;
          right: 70px;
          width: 37px;
          height: 1px;
          background: black;
          top: 9px;
        }

      }
    }

  }

  @media only screen and (min-width: $mediumScreens) {
    > article {
      // width: span(12);
      .info-container {
        // text-align: center;
        padding: 30px 40px;
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > ul {
          // margin: 0 auto;
          // max-width: 1200px;
          margin-left: 0;
          width: calc(100% - 90px);
          margin-top: 0px;

          // height: 30px;
          // display: flex;
          flex-wrap: wrap;
          // justify-content: space-around;
          > li {
            width: auto;
            list-style-type: none;
            box-sizing: border-box;
            //background: #000;
            padding: 0px 19px 0 0;
            a {
              width: 100%;
              // font-weight: bold;
              box-sizing: border-box;
              height: 100%;

              &:hover {
                // color: #000;
              }
            }
          }
        }
      }

    }
  }
  @media only screen and (min-width: $largeScreens) {
    > article {

      .info-container {
        // display: flex;
        padding: 20px 40px;
        // justify-content: space-between;
        max-width: 1150px;

        > ul {
          width: 100%;
          margin-top: 0;
          //margin-left: 129px;
        }
      }

    }
  }

}

.container.showreel-container {

  &.skew {
    transform: translateZ(1px) skewY($skew);
    //overflow: hidden;
    transition: all 300ms;
    //margin-top: 200px;
  }
  h1 {
    font-size: 45px;

    color: $secondaryColor;
    margin: 0 10px;
    display: none;
  }
  .overlay {
    height: 100%;
    //background: aqua;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .data-table {
      height: 100%;
      display: table;
      z-index: 1;
      margin: 0 auto;
      opacity: 1;
      .data-cell {
        display: table-cell;
        vertical-align: middle;
        transition: all 500ms;
        transform: translateZ(1px) skewY($skewplus) scale(1);
        .button {
          width: 50px;
          height: 65px;
          margin: 0 auto;
          opacity: .5;
          font-size: 60px;
          span {
            width: 50px;
            height: 65px;
            color: #fff;
          }
        }
      }

    }
  }
  > article {
    position: relative;
    overflow: hidden;

    .aspect-image {
      opacity: 1;
    }
    .showreel-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      margin-top: -10%;

      width: 100%;
      video {
        width: 100%;
        transform: translateZ(1px) skewY($skewplus) scale(1);

      }
      img {
        width: 100%;
      }
    }
  }
  &:hover {
    video {
    }
    .overlay {
      .data-table {
        .data-cell {
          transform: translateZ(1px) translateY(-5px) skewY($skewplus) scale(1.2);
        }
      }
    }

  }
  @media only screen and (min-width: $mediumScreens) {
    &.skew {

    }

  }
  @media only screen and (min-width: $largeScreens) {

  }
}

.container.slides {

  //margin-bottom: 100px;
  display: none;

  .slick-arrow {
    display: none !important;
  }

  .slick-slide {

      .slide-content {

        &.slide-1, &.slide-2, &:not(.slide-0) {

          .slide-content-absolute {

            article {

              p {
                font-size: 24px;
                font-weight: bold;
                line-height: 1;

                @media only screen and (min-width: 480px) {
                  font-size: 35px;
                }
                @media only screen and (min-width: 1025px) {
                  font-size: 45px;
                }
              }
            }
          }
        }

        &.slide-3 {

          .slide-content-absolute {

            article {

              h1 {
                font-size: 24px;
                font-weight: bold;
                line-height: 1;
                margin: 0;

                @media only screen and (min-width: 480px) {
                  font-size: 35px;
                }
                @media only screen and (min-width: 1025px) {
                  font-size: 45px;
                }
              }
            }
          }
        }
      }

  }

  .slide-content {
    position: relative;
    .aspect-image {
      img {
        width: 100%;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(212, 39, 62, 0.58);
    }
    .slider-background {
      position: absolute;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img, video {
        width: 100%;
      }
    }
    .slide-content-absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      article {
        padding: 10px;
        width: 100%;
        h1 {
          color: #fff;
        }
        p {
          font-size: 10px;
          color: #fff;
        }
        .slide-index{
          color: #fff;
          width: 75px;
          padding: 10px 0 5px 0;
          border-bottom: 3px solid #fff;
          font-size: 9px;
          position: absolute;
          bottom: 10px;
        }
      }
    }

  }
  .slick-dots {
    bottom: 50px;
  }
  @media only screen and (min-width: $mediumScreens) {
    display: block;
    .slide-content {

      .aspect-image {
        img {

        }
      }

      .slide-content-absolute {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        article {
          width: calc(100% - 100px);
          margin-right: 100px;

          p{
            font-size: 18px;
          }
        }
      }

    }
    .slick-dots {

    }
  }
  @media only screen and (min-width: 950px) {
    .slide-content {
      .slide-content-absolute {
        article {
          width: calc(50% - 100px);
        }
      }
    }
  }
  @media only screen and (min-width: $bigScreens) {
    .slide-content {

      .aspect-image {
        img {

        }
      }

      .slide-content-absolute {
        article {

          width: calc(50% - 400px);
          margin-right: 400px;
          p{
            font-size: 20px;
          }
        }
      }

    }
    .slick-dots {

    }
  }

}

.container.featured {

  &.skew {
    transform: translateZ(1px) skewY($skew);
    overflow: hidden;
    //margin-bottom: 150px;
  }
  .projects-container {

    > .project {
      position: relative;
      margin: 0 0;

      box-sizing: border-box;
      float: left;
      // width: span(12);
      width: 100%;
      position: relative;

      &.home_noticias {
        width: 100%;

        .aspect-image {
          padding-bottom: 100px;

          @media (min-width: 480px) {
            padding-bottom: 0;
          }
        }
      }

      .aspect-image {
        opacity: 0;
        // transform: translateZ(1px) skewY($skewplus) scale(1);
      }
      .overlay {
        position: absolute;
        height: 100%;
        transform: scale(1);
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
        transition: all 100ms;
        opacity: 0;
        .data-table {
          height: 100%;
          display: table;
          z-index: 10;
          margin: 0 auto;
          opacity: 1;
          .data-cell {
            display: table-cell;
            vertical-align: middle;
            transition: all 500ms;
            // transform: translateZ(1px) skewY($skewplus) scale(1);
            .button {
              width: 50px;
              height: 65px;
              margin: 0 auto;
              opacity: 1;
              font-size: 40px;
              span {
                width: 50px;
                height: 65px;
                color: #fff;
              }
            }
          }

        }
      }

      .project-container {
        overflow: hidden;
        position: relative;
        .background-container {
          position: absolute;
          width: 101%;
          height: 100%;
          overflow: hidden;
          top: 0;
          left: 0;
          img {
            opacity: 1;
            width: 100%;
            // margin-top: -10%;
            transition: all 300ms;
            // transform: skewY($skewplus) scale(1);

          }

        }
      }

      .project-text {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        z-index: 1;
        opacity: 1;
        padding: 0;
        text-align: left;
        //padding:10px;
        transform: scale(1);
        // text-transform: uppercase;
        transition: all 300ms;
        height: 100%;
        box-sizing: border-box;
        background: black; /* Old browsers */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
        display: flex;
        align-items: flex-end;

        h3 {
          //position: absolute;
          bottom: 0;
          width: 100%;
          min-height: 50px;
          //background: $secondaryColor;

          color: $textColorOverSecondaryColor;

          font-size: 45px;
          font-weight: 100;
          box-sizing: border-box;
          margin: 0;
          padding: 40px;

          @media only screen and (min-width: $largeScreens) {
            width: 50%;
          }
        }

        &.project-text_home_noticia {

          .descripcion_home_noticia {
            color: white;
            font-weight: 300;
            padding: 10px;
            display: none;
            max-width: 70%;
            height: 100%;
            font-size: 12px;
            overflow: auto;

            @media (min-width: 480px) {
              padding: 30px;
              max-width: 50%;
              height: unset;
              font-size: 16px;
              overflow: unset;
              display: block;
            }
          }
        }
      }
      &:hover {
        .overlay {
          //display: block;
          opacity: 1;
          .data-cell {
            // transform: translateY(10px) scale(1);
          }
        }
        .background-container {
          img {
            // transform: translateY(-30px) scale(1);
          }
        }
        .project-text {
          //background: #fff;
          color: #000;
          transform: scale(1.0);
          opacity: 1;
          //top:auto;
          top: 0;
          display: flex;
          align-items: flex-end;

          h3 {

            //font-size: 20px;
          }
        }
      }

    }
  }

  @media only screen and (min-width: $mediumScreens) {

    .projects-container {
      > .project {
        // width: span(6);
        width: 50%;
      }
      //> .project:nth-child(4) {
      //  display: none;
      //}
      > .project {
        .project-text {
          transform: scale(1);
          min-height: 40px;
        }
      }

    }

  }
  @media only screen and (min-width: $largeScreens) {
    .projects-container {
      > .project {
        .project-text {
          transform: scale(1);
          min-height: 50px;
        }
      }
    }
  }
  @media only screen and (min-width: $bigScreens) {
    .projects-container {
      > .project {
        // width: span(4);
        // width: 33.33%;
      }

    }
  }
}

.player-container {
  position: fixed;
  z-index: 101;

  background: $mainColor;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  article {
    width: 100%;
  }

    .plyr {
      max-height: 100vh;
    }
  .content {
    background: $secondaryColor;
  }
  header {
    height: 10vh;
    //display: none;
    color: #fff;
    position: fixed;
    z-index: 1000000;
    width: 100%;
    top: 0;

    .close-icon {
      position: absolute;
      right: 50px;
      top: 4vh;
      cursor: pointer;
      font-size: 30px;
    }
  }

  .plyr--full-ui {
    &.plyr--video {
      .plyr__control--overlaid {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(26,175,255,1);
        width: 80px;
        height: 80px;
        top: 50%;

        @media screen and (min-width: 1900px) {
          top: 57%;
        }

        svg {
          height: 30px;
          left: auto;
          position: relative;
          width: 30px;
        }

      }
    }
  }
}

.videocontainer-appear {
  transform: scaleY(0);
  transition: all 2000ms ease-in;

}

.videocontainer-appear.videocontainer-appear-active {
  transform: scaleY(1);

}

.videocontaineriframe-appear {
  opacity: 0;
  transition-delay: 5s;

}

.videocontaineriframe-appear.videocontaineriframe-appear-active {
  opacity: 1;

}

.bloques {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column-reverse;

  //padding: 0 65px;
  position: relative;
  //min-height: 428px;

  @media only screen and (min-width: $mediumScreens) {
    width: 50%;
  }

  &.solo-txt {

    .bloque {

      &:nth-child(2) {
        display: none;
      }
      &.noticias {
        height: 100%;
      }
    }
  }

  &.solo-redes {
    width: 100%;

    .bloque {
      &:nth-child(2) {
        position: relative;
      }

      &.noticias {
        display: none;
      }
    }

    .aspect-image {
      display: none;
    }

    .project-text {
      position: relative;
    }
  }

  .aspect-image {
    opacity: 0;
    width: 100%;
  }

  .project-text {
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0;
    z-index: 1;
    opacity: 1;
    padding: 0;
    text-align: left;
    transform: scale(1);
    transition: all 300ms;
    height: 100%;
    box-sizing: border-box;
  }

  .bloque {
    width: 100%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    //@media only screen and (min-width: $mediumScreens) {
    //  width: 50%;
    //}

    .rojo {
      width: 100%;
      background: #c21037;
      background: -moz-linear-gradient(left, #c21037 0%, #ef5a5e 100%);
      background: -webkit-linear-gradient(left, #c21037 0%,#ef5a5e 100%);
      background: linear-gradient(to right, #c21037 0%,#ef5a5e 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c21037', endColorstr='#ef5a5e',GradientType=1 );
      font-size: 25px;
      min-height: 242px;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        padding-right: 25px;

        &:last-child {
          padding-right: 0;
        }

        &:hover {
          color: #F7F7F7;
        }
      }

      @media only screen and (min-width: $mediumScreens) {
        width: 50%;
      }

    }
    .violeta {
      width: 100%;
      background: #6A3795;
      font-size: 20px;
      font-weight: 600;
      min-height: 242px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (min-width: $mediumScreens) {
        width: 50%;
      }

      @media screen and (min-width: 1320px) {
        font-size: 26px;
      }
    }

    &.noticias {
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      color: black;
      padding: 0 30px;
      height: 47%;
      //min-height: 242px;

      @media only screen and (min-width: $largeScreens) {
        font-size: 24px;
        line-height: 26px;
        padding: 0 65px;
      }

      p {
        margin: 0;
      }

      b {
        font-weight: 500;
      }
    }
  }
}
