// $susy: (
//         'svg-grid-colors': hsl(180, 50%, 80%),
//         'columns': susy-repeat(12),
//         'container-spread': 'wide',
//         'gutters': 0.25,
// );

.footer {
  color: $secondaryColor;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 480px;
  padding: 40px;
  margin: 0 auto;
  //margin-top: 250px;

  > div {
    width: 100%;
    // width: span(12);
    // float: left;
    // margin-left: gutter() /2;

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 5px 0;
      color: $secondaryColor;
      opacity: 1;
      line-height: 1;
    }

  }
  > div:nth-child(1) {
    width: 100%;

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 5px 0;
      color: $secondaryColor;
      opacity: 1;



    }
  }
  .social {
    font-size: 15px;
    text-align: left;
    padding-top: 15px;
    // width: span(7);
    span {
      margin: 20px 10px 0;
    }
  }
  .untref-logo {
    margin-top: 40px;
    // width: span(5);
    img {
      max-width: 100%;
      width: 150px;
      //margin-bottom: px;

    }
  }

  @media only screen and (min-width: $mediumScreens) {
    max-width: 960px;
    margin-top: 110px;
    // padding-bottom: 110px;
    padding: 0 40px 110px;

    > div {
      width: auto;
      // float: left;
      //
      // margin: 0 gutter() / 2;

    }

    > div:nth-child(1) {
      // padding-top: 22px;
      text-align: left;
      // margin-left: span(1.5);
      // margin-left: 84px;
      // width: span(2);
      width: 15.5%;

    }
    > div:nth-child(2) {
      padding-top: 22px;
      // text-align: center;
      // width: span(5);
      font-size: 30px;
      span {
        margin: 0px 10px 0;
      }
    }
    > div:nth-child(3) {
      // width: span(2);
      // margin-right: span(1.5);

      // text-align: right;

    }

    .untref-logo {
      margin-top: 0px;
    }
  }
  @media only screen and (min-width: $largeScreens) {
    max-width: 1150px;
    padding: 0 40px 110px;
  }
}
