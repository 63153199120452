
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  font-weight: 200;
  background: #fff;
  //overflow: hidden;

  //background: url('http://localhost:3000/assets/img/giphy.gif') repeat;
  //background-size: cover;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2d2f48+0,000000+100 */
  //background: #2d2f48; /* Old browsers */
  //background: -moz-linear-gradient(top, #1c1d2d 0%, #2d2f48 100%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(top, #1c1d2d 0%,#2d2f48 100%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(to bottom, #1c1d2d 0%,#2d2f48 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d2f48', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-master {
  background-color: transparent;
  margin-top: -1px;
  overflow: visible;
}

.logo {
  //position: absolute;
  //z-index: 100;
  //top: 0;
  //left: 0;
  //width: 100%;
  width: 100px;
  height: 53px;
  //height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: $mediumScreens) {

  }
  @media only screen and (min-width: $largeScreens) {
    //position: fixed;
  }
}

img {
  width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus {
  outline: none;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;

  .plyr--full-ui {
    &.plyr--video {
      .plyr__control--overlaid {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(26,175,255,1);
        width: 80px;
        height: 80px;
        top: 50%;

        // @media screen and (min-width: 1900px) {
        //   top: 57%;
        // }

        svg {
          height: 30px;
          left: auto;
          position: relative;
          width: 30px;
        }

      }
    }
  }
}

.videoWrapper iframe {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
}

.loader {
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 50px 0;

}


.hide-for-small{

  display: none;
  @media only screen and (min-width: $mediumScreens) {
    display: block;
  }

}